/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Main from "./main"
import Menu from "../components/menu"

import { useGlobalState, dispatch } from "../states/settings-state"

import "../styles/main.scss"

const Layout = ({ children }) => {
  const [value] = useGlobalState("settings")
  const { isMenuOpen } = value

  const toggleMenu = () => {
    dispatch({
      isMenuOpen: !isMenuOpen,
      type: "setMenu",
    })
  }

  return (
    <div id="app">
      <Menu
        pageWrapId="page-wrap"
        outerContainerId="app"
        isMenuOpen={isMenuOpen}
        handleOnClose={toggleMenu}
      />
      <div id="page-wrap">
        <Main>{children}</Main>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
