import { createStore } from "react-hooks-global-state"

export const { dispatch, useGlobalState } = createStore(
  (state, action) => {
    switch (action.type) {
      case "setMenu":
        return {
          ...state,
          settings: {
            ...state.settings,
            isMenuOpen: action.isMenuOpen,
          },
        }
      default:
        return state
    }
  },
  {
    settings: {
      isMenuOpen: false,
    },
  }
)
