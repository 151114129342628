import React from "react"
import { push as ReactBurgerMenu } from "react-burger-menu"
import { Link } from "gatsby"

export default function Menu({
  pageWrapId,
  outerContainerId,
  isMenuOpen,
  handleOnClose,
}) {
  var styles = {
    bmBurgerBars: {
      background: "#373a47",
    },
    bmBurgerBarsHover: {
      background: "#a90000",
    },
    bmCrossButton: {
      height: "24px",
      width: "24px",
    },
    bmCross: {
      background: "#bdc3c7",
    },
    bmMenuWrap: {
      position: "fixed",
      height: "100%",
    },
    bmMenu: {
      background: "rgba(0, 0, 0, 0.8)",
      padding: "2rem",
      fontSize: "2rem",
    },
    bmMorphShape: {
      fill: "#373a47",
    },
    bmItemList: {
      height: "fit-content",
    },
    bmItem: {
      display: "block",
      color: "white",
      padding: "2rem 0",
      outline: "none",
      fontFamily: "Oswald, sans-serif",
    },
    bmOverlay: {
      background: "rgba(0, 0, 0, 0.6)",
    },
  }

  function closeMenu() {
    document.querySelector(".bm-cross-button button").click()
  }

  return (
    <ReactBurgerMenu
      width={"26rem"}
      customBurgerIcon={false}
      styles={styles}
      pageWrapId={pageWrapId}
      outerContainerId={outerContainerId}
      isOpen={isMenuOpen}
      onStateChange={state => {
        if (state.isOpen === false) {
          handleOnClose()
        }
      }}
    >
      <Link onClick={closeMenu} to="/">
        Home
      </Link>
      <Link onClick={closeMenu} to="/about/">
        beneath the decorated layer
      </Link>
      <Link onClick={closeMenu} to="/contact/">
        become part of the philosophy of birthdays
      </Link>
      <Link onClick={closeMenu} to="/enter-the-institute/">
        enter the institute
      </Link>
    </ReactBurgerMenu>
  )
}
